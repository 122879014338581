import { SUBNAV_CLOSE, triggerEvent } from '@fec/assets/js/utils/event';

export function init() {
  fixNavItems();

  window.addEventListener('popstate', function () {
    triggerEvent(SUBNAV_CLOSE);
    fixNavItems();
  });
}

const getNavItemThatShouldBeActive = () => {
  const currentUrl = window.location.pathname + window.location.hash;
  let activeEntryText;

  if (
    currentUrl.startsWith(
      '/sport/resultcenter/results#/fifa-wc-2023/results/football/wm-women',
    )
  ) {
    activeEntryText = 'Resultate';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/fifa-wc-2023/gameplan')
  ) {
    activeEntryText = 'Spielplan';
  } else if (
    currentUrl.startsWith(
      '/sport/resultcenter/results#/fifa-wc-2023/statistics',
    )
  ) {
    activeEntryText = 'Torschützinnen';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/fifa-wc-2023/team')
  ) {
    activeEntryText = 'Teams';
  } else if (currentUrl.startsWith('/sport/resultcenter/results#/results')) {
    activeEntryText = 'Live & Resultate';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/live/football') &&
    currentUrl.includes('mac=') /* mac = Multi Angle Camera */
  ) {
    activeEntryText = 'News';
  } else if (
    currentUrl.startsWith('/sport/fussball/fifa-wm-2023') ||
    currentUrl.startsWith('/sport/fussball/fifa-frauen-wm-2023')
  ) {
    activeEntryText = 'News';
  } else if (
    currentUrl.startsWith(
      '/sport/resultcenter/results#/euro-2024/results/football/euro',
    )
  ) {
    activeEntryText = 'Resultate';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/euro-2024/gameplan')
  ) {
    activeEntryText = 'Spielplan';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/euro-2024/statistics')
  ) {
    activeEntryText = 'Torschützen';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/euro-2024/team')
  ) {
    activeEntryText = 'Teams';
  } else if (
    currentUrl.startsWith('/sport/resultcenter/results#/olympics/epg')
  ) {
    activeEntryText = 'Livestreams';
  }

  // search for the navItem that should be set active and return it
  if (activeEntryText) {
    const $navItemToSetActive = $('.nav-item').filter(
      (index, element) =>
        element.childNodes[0].textContent.trim() === activeEntryText,
    );

    if ($navItemToSetActive?.length === 1) {
      return $navItemToSetActive.first();
    }
  }

  return false;
};

const fixNavItems = () => {
  const $navItemThatShouldBeActive = getNavItemThatShouldBeActive();

  // couldn't find anything, giving up here.
  if (!$navItemThatShouldBeActive) {
    return;
  }

  // set all sub-nav-items inactive, i.e. remove offscreen text and classes
  $('.js-active-nav-item').each((i, el) =>
    $(el)
      .removeClass('nav-item--active js-active-nav-item')
      .find('.h-offscreen')
      .remove(),
  );

  $navItemThatShouldBeActive
    .addClass('nav-item--active js-active-nav-item')
    .append('<span class="h-offscreen">, aktiver Navigationspunkt</span>');

  if ($navItemThatShouldBeActive.hasClass('js-3rd-level-nav-item')) {
    // set the parent navItem to active as well
    $navItemThatShouldBeActive
      .closest('.js-nav-group')
      .children('.js-nav-item')
      .addClass('nav-item--active js-active-nav-item')
      .append('<span class="h-offscreen">, aktiver Navigationspunkt</span>');
  }
};
