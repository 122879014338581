/**
 * Assets for Landingpages (Web)
 */

// styles
import '../css/main.scss';
import '../css/survey-gizmo-rlp.scss';
import '../css/search.scss';

// common js
import { commonWeb } from '../../common/js/main.js';

// content
import { init as mediumInit } from '@fec/frontend/modules/content/medium/init';
import { TouchDetection } from '@fec/frontend/foundation/client/touch';
import { init as srfTeaserInit } from './srf-rlp-teasers';
import { init as jobOfferConsoleInit } from './srf-job-offer-console';
import { init as srfIframeEventTrackInit } from '../../webview/js/srf-iframe-event-track';
import { init as srfTeaserMetaInit } from '@fec/frontend/elements/text/teaser-meta/teaser-meta.controller';
import { init as srfTeaserLinkInit } from '@fec/frontend/elements/links/teaser-ng-link/teaser-ng-link';
import { init as srfListCollectionInit } from '@fec/frontend/modules/collections/list/list';

import { init as srfSubnavHackInit } from './srf-subnav-hack';
// Immediately fix the navi
srfSubnavHackInit();

$(document).ready(function () {
  // for css styles depending on touch capability
  if (TouchDetection.isTouchSupported()) {
    $('body').addClass('has-touch');
  } else {
    $('body').addClass('no-touch');
  }

  if ($('body').data('bu').toLowerCase() === 'srf') {
    jobOfferConsoleInit();
  }

  commonWeb();
  mediumInit();
  srfListCollectionInit();
  srfTeaserInit();
  srfIframeEventTrackInit();
  srfTeaserMetaInit();
  srfTeaserLinkInit();
});
