/* eslint-disable no-console */
export function init () {
    const jobPortalLogo = `
   SSSSSSSSSSSSSSS RRRRRRRRRRRRRRRRR   FFFFFFFFFFFFFFFFFFFFFF
 SS:::::::::::::::SR::::::::::::::::R  F::::::::::::::::::::F
S:::::SSSSSS::::::SR::::::RRRRRR:::::R F::::::::::::::::::::F
S:::::S     SSSSSSSRR:::::R     R:::::RFF::::::FFFFFFFFF::::F
S:::::S              R::::R     R:::::R  F:::::F       FFFFFF
S:::::S              R::::R     R:::::R  F:::::F             
 S::::SSSS           R::::RRRRRR:::::R   F::::::FFFFFFFFFF   
  SS::::::SSSSS      R:::::::::::::RR    F:::::::::::::::F   
    SSS::::::::SS    R::::RRRRRR:::::R   F:::::::::::::::F   
       SSSSSS::::S   R::::R     R:::::R  F::::::FFFFFFFFFF   
            S:::::S  R::::R     R:::::R  F:::::F             
            S:::::S  R::::R     R:::::R  F:::::F             
SSSSSSS     S:::::SRR:::::R     R:::::RFF:::::::FF           
S::::::SSSSSS:::::SR::::::R     R:::::RF::::::::FF           
S:::::::::::::::SS R::::::R     R:::::RF::::::::FF           
 SSSSSSSSSSSSSSS   RRRRRRRR     RRRRRRRFFFFFFFFFFF           
`;
    const jobPortalLink = 'https://jobs.srf.ch/de/jobs';

    console.log(`%c${jobPortalLogo}`, 'font-family:monospace');
    console.log(
        '%cYou are using the console, seems like you know some coding :)',
        'font-size: 20px'
    );
    console.log(
        '%cAre you interested in working with us?: ' + jobPortalLink,
        'color: grey; font-size: 15px'
    );
}
